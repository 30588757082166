

















import { Component, Vue } from "vue-property-decorator";
import AppLayout from "../components/commons/layouts/AppLayout.vue";
import TabRouter from "../components/commons/TabRouter.vue";
import Table from "../components/commons/tables/Table.vue";
import TableHeader from "../components/commons/tables/TableHeader.vue";
import ActionsWrapper from "../components/commons/tables/ActionsWrapper.vue";
import Button from "../components/commons/inputsAndControls/Button.vue";
import { Routes } from "../enums";

@Component({
  components: {
    AppLayout,
    Button,
    Table,
    TabRouter,
    TableHeader,
    ActionsWrapper,
  },
})
export default class ProcessManagementPage extends Vue {
  routes = [
    {
      title: "Úkony",
      to: Routes.OperationTemplates,
      error: "",
    },
    {
      title: "Podkódy",
      to: Routes.ProcessSubCodes,
      error: "",
    },
    {
      title: "Procesy",
      to: Routes.ProcessTemplates,
      error: "",
    },
    {
      title: "Přiřazení",
      to: Routes.ProcessCodes,
      error: "",
    },
  ];
}
